// Pour le moment pas une priorité... on verra par la suite !!!

import { createSlice } from "@reduxjs/toolkit";




// Ajout de plusieurs blocs
// action = { blocs: {numero : {data}, ...} }
const addBlocs = (state, action) => {
    return { ...state, ...action.payload.blocs }
}


// Update de plusieurs blocs
// action = { {numero : {data to update}, ...} }
const updateBlocs = (state, action) => {
    for (const [numero, dicoUpdate] of Object.entries(action.payload)) {
        let old_datas = (Object.keys(state).includes(numero)) ? state[numero] : {} // Json vide si on ajoute le bloc
        state[numero] = { ...old_datas, ...dicoUpdate }
    }
}




export const BlocsSlice = createSlice({
    name: 'blocs',
    initialState: {},
    reducers: {
        addBlocs: addBlocs,
        updateBlocs: updateBlocs,

    }
})



export const getBlocData = (state, numero) => state.blocs[numero]


