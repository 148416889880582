
import { React, useEffect } from "react" //
import { useLocation, Navigate, Outlet } from "react-router-dom" // Route
// import { getStorages } from "./Storage"
import { useNavigate } from "react-router-dom"
import axios from 'axios'

import { store } from "../../Redux/Store.js"
import { getUserAndToken, UserConnectedSlice, getUserConnected } from '../../Redux/UserConnectedSlice'

import { loadStorageInReduxStore } from './../../Redux/SaveLoadStore.js'
import Menu from '../Menu/Menu.js'


// Sécurité
// https://stackoverflow.com/questions/48983708/where-to-store-access-token-in-react-js
// https://medium.com/lightrail/getting-token-authentication-right-in-a-stateless-single-page-application-57d0c6474e3


// On transmet le role au composant Outlet car il est utile en cas de chargement du LocalStorage avec redirection vers la page d'accueil
// Il pourra être utile à d'autres Composant "Outlet"
// https://stackoverflow.com/questions/63765196/pass-props-to-outlet-in-react-router-v6
// !!! Finalement pas besoin du role dans l'Outlet !!!


function getNNewNotifs() {
    let dataToSend = getUserAndToken(store.getState())

    axios.post("/platform/get_n_notifs", dataToSend)
        .then(res => {
            if (res.data?.n_new_notifs !== undefined) {
                // Update store
                store.dispatch(UserConnectedSlice.actions.updateNNewNotifs({ nNewNotifs: res.data.n_new_notifs }))
                // console.log(res.data.n_new_notifs)
            }
        })
}


export default function Authentification() {
    const location = useLocation()
    // const navigate = useNavigate()
    let goToConnexion;
    let role;

    // On récupère le userConnected dans le store, il peut être vide si le user n'est pas connecté
    const userConnected = getUserConnected(store.getState())
    // console.log(userConnected)

    // Si le store est vide
    if (userConnected.user === undefined) {
        // Si les datas du userConnected sont dans le localStorage on charge le localStorage
        [goToConnexion, role] = loadStorageInReduxStore()
        // console.log('goToConnexion =', goToConnexion)

    } else {
        goToConnexion = false
    }


    // On interroge le backend régulièrement pour avoir le nb de nouvelles notifs
    useEffect(() => {
        getNNewNotifs()
        const interval = setInterval(() => getNNewNotifs(), 5 * 60 * 1000)
        return () => {
            clearInterval(interval);
        }
    })


    return (
        (goToConnexion) ?
            <Navigate to="/login" state={{ from: location }} replace />
            : <Menu>
                <Outlet />
                {/* <Outlet context={role}/> */}
            </Menu>

    )
}


