// Coureur de la page sur lequel on est

import { createSlice } from "@reduxjs/toolkit";




// Ajout sélection d'un coureur
const updateNNewNotifs = (state, action) => {
    state['n_new_notifs'] = action.payload.nNewNotifs
}

const decrementNNewNotifs = (state) => {
    state['n_new_notifs'] --
}



export const UserConnectedSlice = createSlice({
    name: 'userConnected',
    initialState: {},
    reducers: {
        updateUserConnected: (state, action) => action.payload,
        updateNNewNotifs: updateNNewNotifs,
        decrementNNewNotifs: decrementNNewNotifs,
    }
})


/////////////////////////////////////////////// Selectors ///////////////////////////////////////////////

// Retourne toutes les datas du state userConnected
export const getUserConnected = (state) => state.userConnected


const NEW_PLATORM = true

// Retourne un json avec les datas du userConnected utiles à toute requête backend
export const getUserAndToken = (state) => {
    const userConnected = state.userConnected

    if (NEW_PLATORM) {
        return {
            'user': userConnected.user,
            'token': userConnected.token,
            'Id_user_connected': userConnected.Id,
        }
    } else {
        return {
            'user': 'old_platform',
        }
    }
}

// Retourne un booléen indiquant si l'utilisateur connecté a accès au droit donné
export const hasAccessUserConnected = (state, droit) => state.userConnected.droits.includes(droit)
export const getRoleUserConnected = (state) => state.userConnected.role
export const getDroitsUserConnected = (state) => state.userConnected.droits
export const getIdUserConnected = (state) => state.userConnected.Id_user_connected
export const isPlatformLocal = (state) => (state.userConnected.local === true)
export const getNNewNotifs = (state) => state.userConnected?.n_new_notifs


