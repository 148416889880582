import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { AvatarsSlice } from "./AvatarsSlice"
import { PlanifSlice } from "./PlanifSlice"
import { ActivitiesSlice } from "./ActivitiesSlice"
import { BlocsSlice } from "./BlocsSlice"
import { CommentsSlice } from "./CommentsSlice"
import { CoureurFocusSlice } from "./CoureurFocusSlice"
import { UserConnectedSlice } from "./UserConnectedSlice"
import { UsersInfosSlice } from "./UsersInfosSlice"
import { CommentsActsSlice } from "./CommentsActsSlice"
import { SelectActsSlice } from './SelectActsSlice'
import { SelectCoureursSlice } from './SelectCoureursSlice'


let initialState = {
    avatars: { 'flags': {}, 'trombines': {}, 'effectifs': {}, 'effectifsCoureurs': {} }, // Avatars, flags, effectifs
    planif: {}, // données calendrier des activités
    activities: {}, // date activités pour planification
    blocs: {}, 
    selectedActs: {}, // sélection des activités
    comments: {}, // commentaires
    commentsActs: {}, // commentaires des activités
    coureurFocus: {}, // coureur page sur laquelle on navigue (nom en haut à droite)
    selectCoureurs: [],
    userConnected: {}, // Les infos concernant l'utilisateur connecté
    usersInfos: {}, // Les infos sur tous les utilisateur (dicos {Id: user}, {user: Id})
}



const appReducer = combineReducers({
    avatars: AvatarsSlice.reducer,
    planif: PlanifSlice.reducer,
    activities: ActivitiesSlice.reducer,
    blocs: BlocsSlice.reducer,
    selectedActs: SelectActsSlice.reducer,
    comments: CommentsSlice.reducer,
    commentsActs: CommentsActsSlice.reducer,
    coureurFocus: CoureurFocusSlice.reducer,
    selectCoureurs: SelectCoureursSlice.reducer,
    userConnected: UserConnectedSlice.reducer,
    usersInfos: UsersInfosSlice.reducer,
})


const rootReducer = (state, action) => {
    // console.log(action.type)
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}



export const store = configureStore(
    {
        preloadedState: initialState,
        reducer: rootReducer
    }
)



// export const store = configureStore(
//     {
//         preloadedState: initialState,
//         reducer: combineReducers({
//             avatars: AvatarsSlice.reducer,
//             planif: PlanifSlice.reducer,
//             activities: ActivitiesSlice.reducer,
//             comments: CommentsSlice.reducer,
//             coureurFocus: CoureurFocusSlice.reducer,
//             userConnected: UserConnectedSlice.reducer,
//             usersInfos: UsersInfosSlice.reducer,
//         })
//     }
// )